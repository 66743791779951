.btn {
	border: 1px solid $primary;
	color: $primary;
	background: transparent;
	text-decoration: none;
	padding: 0 15px !important;
	border-radius: 10px !important;
	font-weight: 400;
	font-size: 18px;
	line-height: 50px !important;

	a {
		color: $primary !important;
		padding: 0 !important;
	}

	&:hover {
		background: $primary;
		color: white;
		border: 1px solid $primary;

		a {
			color: white !important;
		}
	}

	&-primary-filled {
		color: #fff;
		background: $primary;
		width: 100%;
	}
}