.top-banner {
	background: $primary;
	color: $grey;
	line-height: 50px;

	a {
		color: $grey;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.banner {
	padding: 20px 0;
	height: 120px;
	position: sticky;
	background: white;
	z-index: 2;
	top: 0;

	h1 {
		margin: 0;
		padding: 0;
	}

	nav {
		padding-top: 0px;
		float: right;

		li {
			margin-left: 44px;
			line-height: 24px;
			padding: 28px 0;

			a {
				text-decoration: none;
				font-size: 20px;
				color: #031624;
				font-weight: bold;
			}

			&.active a {
				color: $primary;
			}
		}
	}
}

.second-banner {
	padding: 70px 0;
	background-color: $primary;
	background-image: url('../../dist/images/Image1.png');
	background-size: cover;
	background-position: center center;
	text-align: center;
	color: #fff;
	font-size: 40px;
	line-height: 48px;

	.page-header {
	    padding-bottom: 0px;
	    margin: 0px 0 0px;
	    border-bottom: 0px solid #eee;

	    h1 {
	    	margin: 0;
	    }
	}
}

.home .second-banner {
	padding: 165px 0;
}