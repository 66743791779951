.content-info {
	background: #031624;
	padding: 50px 0;
	color: white;
	font-size: 16px;
	line-height: 24px;

	.right {
		text-align: right;
	}

	span {
		font-size: 20px;
		line-height: 24px;
		font-weight: bold;
	}

	a {
		text-decoration: none;
	}

	.eqda {
		font-size: 14px;
	}

	h2 {
		margin: 0 0 20px;
		font-weight: bold;
		font-size: 20px;
	}

	.rft-logo {
		padding-top: 120px;
		text-align: right;

		@media #{$mobile} {
			padding: 15px 0 30px;
			text-align: center;
		}
	}

	@media #{$mobile} {
		padding: 40px 0;
		text-align: center;
		font-size: 16px;

		span {
			font-size: 16px;
		}

		.right {
			text-align: center;
			margin-top: 20px;
		}
	}
}