* {
    text-decoration-skip-ink: auto;
    transition: all 0.2s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background: rgba(255,0,0,0.3);

    &:before,
    &:after,
    &:hover {
   	    text-decoration-skip-ink: auto;
	    transition: all 0.2s ease;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
    }
}

body {
	line-height: 1.45em;
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	background: #fff;
	color: $grey;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.45em;
	font-family: 'Roboto', sans-serif;
}

h1.section-title {
	margin: 30px 0;
	font-size: 22px;
}

a {
	color: #fff;
	text-decoration: underline
}

p {
	margin-bottom: 25px;
}

#searchsubmit {
	background: $primary;
	color: #fff;
	height: 51px;
	border: 0;
	position: relative;
	top: 1px;
	margin: 0px 0 0 -4px;
	padding: 0;
	width: 70px;
	text-align: center;
	line-height: 51px;
	border-radius: 0 10px 10px 0;
}

.mobile-trigger {
	display: none;

	@media #{$tablet} {
		display: block;
		line-height: 80px;
		font-size: 30px;
		color: $primary;
		display: block;
		text-align: center;
		float: right;
		margin-right: 15px;

		&:focus,
		&:active,
		&:hover {
			color: $primary;
		}
	}
}


.mobile {
	display: none;
	background-color: darken($primary, 2%);

	@media #{$tablet} {
		display: block;
		position: sticky;
		z-index: 5;
		top: 41px;

		ul {
			list-style: none;
			padding: 0;

			a {
				padding: 15px;
				display: block;
				color: #fff;
			}
		}
	}	
}

.navbar-collapse.collapse {
	display: none !important;

	&.in {
		display: block !important;

		li {
			display: block;
			float: none;
		}
	}
} 

img {max-width: 100%;height: auto;}
.text-center {text-align: center;}
.mt15 {
	margin-top: 15px;
 }
.mt30 {
	margin-top: 30px;
 }
.mt60 {
	margin-top: 60px;

	@media #{$mobile} {
		margin-top: 30px;
	}
 }
.mt90 {
	margin-top: 90px;

	@media #{$mobile} {
		margin-top: 30px;
	}
 }
.mt120 {
	margin-top: 120px;
 }
.mt150 {
	margin-top: 150px;
 }
.mb15 {
	margin-bottom: 15px;
 }
.mb30 {
	margin-bottom: 30px;
 }
.mb60 {
	margin-bottom: 60px;

	@media #{$mobile} {
		margin-bottom: 30px;
	}
 }
.mb90 {
	margin-bottom: 90px;

	@media #{$mobile} {
		margin-bottom: 30px;
	}
 }
.mb120 {
	margin-bottom: 120px;
 }
.mb150 {
	margin-bottom: 150px;
 }