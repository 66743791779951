.product-search {
	background-color: #031624;
	padding: 35px;

	@media #{$mobile} {
		padding: 0 15px;
	}

	label {
		width: calc(100% - 74px);
	}

	ul {
		margin: 0;

		li {
			margin: 0;
			padding: 0;
			display: inline-block;

			&:first-child {
				width: 210px;
				color: #fff;
				line-height: 51px;
			}
			&:last-child {
				width: calc(100% - 215px);

				input[type="search"] {
					height: 51px;
					line-height: 21px;
					padding: 15px 30px;
					border: 0;
					border-radius: 10px 0 0 10px;
					color: #031624;
					width: 100%;
				}
			}

			@media #{$mobile} {
				
				&:first-child {
					width: 100%;
					color: #fff;
					line-height: 51px;
					text-align: center;
				}
				&:last-child {
					width: 100%;

					input[type="search"] {
						height: 51px;
						line-height: 21px;
						padding: 15px 30px;
						border: 0;
						border-radius: 10px 0 0 10px;
						color: #031624;
						width: 100%;
					}
				}
			}
		}
	}
}

.quality {
	padding: 60px 0;
	text-align: center;

	.text {
		width: 660px;
		font-size: 30px;
		line-height: 37px;
		position: relative;
		text-align: center;
		margin: 0 auto;

		&:before, &:after {
			height: 5px;
			width: 220px;
			position: absolute;
			content: '';
			top: 35px;
			background: $primary;
		}

		&:before {
			left: -250px;
		}

		&:after {
			right: -250px;
		}

		@media #{$desktop} {
			width: 580px;

			br {
				display: none;
			}

			&:before, &:after {
				height: 5px;
				width: 150px;
				position: absolute;
				content: '';
				top: 35px;
				background: $primary;

				@media #{$tablet} {
					top: 0;
				}
			}

			&:before {
				left: -170px;
			}

			&:after {
				right: -170px;
			}
		}

		@media #{$mobile} {
			width: 100%;

			br {
				display: none;
			}

			&:before, &:after {
				height: 5px;
				width: 150px;
				position: absolute;
				content: '';
				background: $primary;

				@media #{$tablet} {
					top: 0;
				}
			}

			&:before {
				top: -25px;
				left: calc(50% - 75px;);
			}

			&:after {
				top: auto;
				bottom: -40px;
				left: calc(50% - 75px;);
			}
		}
	}
}

.featured-equipment {
	background: #F2F2F2;
	padding: 60px 0 60px;

	h1 {
		font-size: 30px;
		line-height: 50px;
		margin: 0 0 40px;

		@media #{$mobile} {
			margin: 0 0 10px;
		}
	}

		.col-sm-5 {
			text-align: right;
		}

	@media #{$tablet} {
		.machine-thumb {
			margin-top: 30px;
		}
	}
}

body.search-results {
	background: #F2F2F2;
}

.machine-content {
	background: white;
	padding: 20px;
	border-radius: 0 0 10px 10px;

	h1 {
		font-size: 20px !important;
		line-height: 24px !important;
		margin: 0 0 16px !important;
		height: 24px;
		overflow: hidden;
	}

	.price-from {
		text-transform: uppercase;
		font-size: 16px;
	}

	.price {
		color: #1D7874;
		font-size: 27px;
		font-weight: 700;
		margin: 0 0 16px;
	}
}

.gallery-title {
	margin: 90px 0 40px;
}

.equip {
	margin-top: 20px;
	.ttu {
		text-transform: uppercase;
		font-size: 16px;
	}

	.ttup {
		margin-bottom: 25px;
		font-size: 20px;

		&.price {
			color: #1D7874;
			font-weight: 700;
			font-size: 27px;
		}
	}

	.buttons {
		width: 260px;

		a {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}

.services-table {
	height: 165px;

	td {
		vertical-align: middle;;
	}
	h1 {
		font-size: 40px;
		margin: 0 0 -4px;
	}
	p {
		font-size: 16px;
		line-height: 24px;
	}

	@media #{$mobile} {
		height: auto;

		h1 {
			font-size: 30px;
		}
	}
}

.services-container {

	@media #{$mobile} {
		.mb90 {
			text-align: center;
		}
	}
}

.eqheight {
	@media #{$mobile} {
		height: auto !important;
		margin-bottom: 30px;
	}
}

.dropdown.open {
	background: $primary;
	color: #fff;
	padding-left: 15px;
	padding-right: 15px;
	margin-left: 29px;
	margin-right: -15px;
	border-radius: 10px 10px 0 0 !important;

	a {
		color: #fff;
	}
}

.dropdown-menu {
	left: auto;
	right: 0;
	background: $primary;
	border: 0;
	border-radius: 10px 0 10px 10px !important;

	li.menu-item {
		border-radius: 10px 0 10px 10px;
		margin: 0 !important;
		padding: 0 !important;
		text-align: right !important;
		background: transparent !important;
		color: white !important;

		a {
			background: transparent !important;
			color: white !important;
			line-height: 34px;
		}
	}
}

label.gfield_label {
	text-transform: uppercase;
	font-size: 12px !important;
}

.ginput_container input,
.ginput_container textarea {
	border: 1px solid $primary;
	border-radius: 10px;
	padding: 5px 15px !important;
}

#gform_submit_button_1 {
	@extend .btn;
	@extend .btn-primary;

	background: #031624;
	color: #fff;
	width: 320px;
	border: 0;
	border-bottom: 60px;

	@media #{$mobile} {
		width: 100%;
	}
}

.service-title {
	font-size: 30px;
	margin: 0 0 60px;
	line-height: 35px;
}

.newsletter-signup {
	background: $primary;
	padding: 20px;
	color: #fff;
	position: sticky;
	top: 525px;

	#mc_embed_signup .mc-field-group input {
		width: calc(100% - 40px);
		border-radius: 10px;

		@media #{$desktop} {
			width: 253px;
		}

		@media #{$mobile} {
			width: 100%;
		}
	}

	div.mce_inline_error {
		display: none !important;
	}

	p {
		margin-bottom: 10px;
	}
}

main .page-header h1 {

	@media #{$mobile} {
		font-size: 18px;
	}
}

.slide-height {
	height: 1125px;
}

.long-signup {
	color: #fff;
	padding: 80px 0;
	background: $primary;

	p {
		font-size: 30px;
	}

	@media #{$mobile} {
		padding: 30px 0;
		line-height: 34px;

	    div#mc_embed_signup form .mc-field-group {
	    	float: left;
	    	width: 360px;
	    	clear: none;

	    	@media #{$mobile} {
    			width: 30%;

    			input {
    				width: 100%;
    			}
    			
	    		&:first-child {
	    			width: 70%;

	    			input {
	    				width: 100%;
	    			}
	    		}
	    	}
	    }
	}
}

hr {
	border-color: #707070;
	margin-top: 0;
}

div#mc_embed_signup {
	margin-top: 10px;

	form {
	    display: block;
	    position: relative;
	    text-align: left;
	    margin: 0px;

	    .mc-field-group {
	    	float: left;
	    	width: 360px;
	    	clear: none;
	    }

	    input[type="email"] {
	    	height: 51px;
		    line-height: 21px;
		    padding: 15px 30px;
		    border: 0;
		    border-radius: 10px 0 0 10px;
		    color: #031624;
		    width: 100%;
	    	width: 360px;
	    	display: block;
	    	clear: none;
	    	float: left;
	    }

	    input[type="submit"] {
	    	background: #031624;
		    color: #fff;
		    height: 51px;
		    border: 0;
		    position: relative;
		    margin: 0;
		    padding: 0;
		    width: 70px;
		    text-align: center;
		    line-height: 50px;
		    border-radius: 0 10px 10px 0;
	    	width: 100px;
	    	display: block;
	    	clear: none;
	    	float: left;
	    	height: 51px;

	    }
	}
}

.machine-thumb {
	img {
		width: 100%;
	}
}

.mainrow {
	background: #F2F2F2;
	padding-top: 50px;
	padding-bottom: 50px;

	&.alt {
		background: #fff;

		.machine-content {
			border: 1px solid $primary;
			border-top: 0;
		}
	}

	h1 {
		font-size: 30px;
		line-height: 52px;
		margin: 0;

		@media #{$mobile} {
			font-size: 20px;
		}
	}

	button {
		float: right;
	}

	@media #{$mobile} {
		padding-top: 20px;
		padding-bottom: 20px;

		.machine-content {
			
			.btn {
				width: 150px;
				margin-bottom: 5px;
			}

			.btn-primary-filled {
				width: 100%;
				margin: 0;
			}
		}
	}
}

.buttons {

	.btn {
		display: inline-block;
		width: 300px;
		margin-top: 19px;
	}

	br {
		height: 19px;
		display: block;
		overflow: hidden;
		display: none;
	}
}

.new-container.alt {
	background: #F2F2F2;
	padding: 60px 0;
}

.page-id-618 .new-container {
	padding: 60px 0;

	@media #{$tablet} {
		text-align: center;

		.new-content br {
			display: none;
		}
	}
}

.thumbnail-overlay {

	img {
		width: 100%;

		@media #{$tablet} {
			margin-top: 60px;
		}
	}

	.overlay-name {
		position: absolute;
		bottom: 10px;
		left: 10%;
		width: 80%;
		background: white;
		height: 62px;
		line-height: 62px;
		text-align: center;
	}
}