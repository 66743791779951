// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Responsive
$mobile: 				"all and (max-width:  768px)";
$tablet: 				"all and (max-width:  991px)";
$desktop: 				"all and (max-width: 1199px)";

// Colors
$primary:         		#6DB8BB;
$grey:					#262C31;
$dgrey:					#F0F2F3;